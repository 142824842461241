import React, { useEffect, useState } from 'react';
import { TextBoxFull, Wrapper, FlexContainer, orange } from '../styles/globalstyles';
import SEO from '../components/seo';
import {css} from '@emotion/react';


const Datenschutz = () => {
    const [cookieState, setCookieState] = useState("")
    useEffect(() =>{
        let cname="accept"
        window.dataLayer = window.dataLayer || [];
        let cookie;
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
            c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
             cookie =  c.substring(name.length, c.length)
             if (cookie == "true"){
                 setCookieState("true")
                window.dataLayer.push({
                    'event': "consent_given",
                    'consent': "true",
                    });
            
            }
            else if (cookie == "false") {
                setCookieState("false")
                window.dataLayer.push({
                    'event': "consent_given",
                    'consent': "false",
            
                    });
                }
            else {setCookieState("false")}
            }
        }
 
        
        }
    )
    const accept = (cvalue) => {
        var cname ="accept"
        var d = new Date();
        var exdays= 90
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        if (cvalue == "true") {
            window.dataLayer.push({
                'event': "consent_given",
                'consent': "true",
                });
        }
        else if (cvalue == "false") {
            window.dataLayer.push({
                'event': "consent_given",
                'consent': "false",
                });
        }
        setCookieState(cvalue)

    }
    
    return(
    <Wrapper>
        <SEO title="Datenschutzerklaerung" />
        <FlexContainer direction="column" align="flex-start" justify="space-between" css={css`padding-top: 8em !important;`}>
        <h1>
            Datenschutzerklärung
        </h1>

        <TextBoxFull>
            <p>Für die Beratungsleistungen gelten immer die im Anschluss formulierten Geschäftsbedingungen. Andere Bestimmungen gelten nicht. Änderungen und Ergänzungen bedürfen der Schriftform.</p>
        </TextBoxFull>
        <TextBoxFull>
            <h4>1. Verantwortlicher i.S.d. Art. 4 Nr. 7 DSGVO</h4>
            <p>Verantwortlicher im Sinne der DSGVO und anderer nationaler Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger datenschutzrechtlicher Bestimmungen ist:</p>
            <p> Proceed GmbH & Co KG <br />
            Salzburger Weg 10 <br />
                79111 Freiburg 
                Büro: 0761 / 897 61 83 </p>

        </TextBoxFull>
        <TextBoxFull>
            <h4>2. Bereitstellung der Website und Erstellung von Logfiles</h4>
            <p>Bei jedem Aufruf unserer Internetseite erfasst unser System automatisiert Daten und Informationen des Computersystems des aufrufenden Rechners. Folgende Daten werden hierbei erhoben:</p>
            <p> (1) Informationen über den Browsertyp und die verwendete Version <br/>
                (2) Das Betriebssystem des Abrufgerätes <br/>
                (3) Die IP-Adresse des Abrufgerätes <br/>
                (4) Datum und Uhrzeit des Zugriffs <br/>
                (5) Websites und Ressourcen (Bilder, Dateien, weitere Seiteninhalte), die auf unserer Internetseite aufgerufen wurden. <br/>
                (6) Websites, von denen das System des Nutzers auf unsere Internetseite gelangte (Referrer-Tracking)
             </p>
             <p>Diese Daten werden in den Logfiles unseres Systems gespeichert. Eine Speicherung dieser Daten zusammen mit personenbezogenen Daten eines konkreten Nutzers findet nicht statt, so dass eine Identifizierung einzelner Seitenbesucher nicht erfolgt.</p>
                <ul>
                    <li>Art. 6 Abs.1 lit.f DSGVO (berechtigtes Interesse). Unser berechtigtes Interesse besteht darin, die Erreichung des nachfolgend geschilderten Zwecks zu gewährleisten.</li>
                    <li>Das Logging erfolgt zur Erhaltung der Kompatibilität unserer Internetseite für möglichst alle Besucher und zur Missbrauchsbekämpfung und Störungsbeseitigung. Hierfür ist es notwendig, die technischen Daten des abrufenden Rechners zu loggen, um so frühestmöglich auf Darstellungsfehler, Angriffe auf unsere IT-Systeme und/oder Fehler der Funktionalität unserer Internetseite reagieren zu können. Zudem dienen uns die Daten zur Optimierung der Website und zur generellen Sicherstellung der Sicherheit unserer informationstechnischen Systeme.</li>
                    <li>Die Löschung der vorgenannten technischen Daten erfolgt, sobald sie nicht mehr benötigt werden, um die Kompatibilität der Internetseite für alle Besucher zu gewährleisten, spätestens aber 3 Monate nach Abruf unserer Internetseite.</li>
                    <li>Die Widerspruchs- und Beseitigungsmöglichkeiten richten sich nach den nachfolgend in dieser Datenschutzerklärung geschilderten generellen Regelungen zum datenschutzrechtlichen Widerspruchsrecht und Löschungsanspruch.</li>
                </ul>
        </TextBoxFull>
        <TextBoxFull>
            <h4>3. Besondere Funktionen der Internetseite</h4>
            <p>Unsere Seite bietet Ihnen verschiedene Funktionen, bei deren Nutzung von uns personenbezogene Daten erhoben, verarbeitet und gespeichert werden. Nachfolgend erklären wir, was mit diesen Daten geschieht
            </p>
            <h5>Kontaktformulare:</h5>
            <ul>
                <li>Die von Ihnen in unsere Kontaktformulare eingegebenen Daten.</li>
                <li>Art. 6 Abs. 1 lit.a DSGVO (Einwilligung durch eindeutige bestätigende Handlung bzw. Verhaltensweise)</li>
                <li>Die über unser Kontaktformular bzw. über unsere Kontaktformulare aufgenommenen Daten werden wir nur für die Bearbeitung der konkreten Kontaktanfrage verwenden, die durch das Kontaktformular eingeht.</li>
                <li>Nach Bearbeitung Ihrer Anfrage werden die erhobenen Daten unverzüglich gelöscht, soweit keine gesetzlichen Aufbewahrungsfristen bestehen.</li>
                <li>Die Widerspruchs- und Beseitigungsmöglichkeiten richten sich nach den nachfolgend in dieser Datenschutzerklärung geschilderten generellen Regelungen zum datenschutzrechtlichen Widerspruchsrecht und Löschungsanspruch.</li>
            </ul>
            <h5>Buchungsanfrage-Formular</h5>
            <ul>
                <li>Die von Ihnen in die Formularfelder eingegebenen Daten.</li>
                <li>Art. 6 Abs.1 lit.b DSGVO (Durchführung (vor)vertraglicher Maßnahmen)</li>
                <li>Der vorvertragliche Informationsaustausch ist notwendig für einen möglichen späteren Vertragsabschluss.</li>
                <li>Die Löschung der Daten erfolgt, sobald diese für die Bearbeitung der Buchung nicht mehr benötigt werden und keine gesetzlichen Aufbewahrungspflichten mehr bestehen.</li>
                <li>Die Widerspruchs- und Beseitigungsmöglichkeiten richten sich nach den nachfolgend in dieser Datenschutzerklärung geschilderten generellen Regelungen zum datenschutzrechtlichen Widerspruchsrecht und Löschungsanspruch.</li>
            </ul>
        </TextBoxFull>
        <TextBoxFull>
            <h4>4. Statistische Auswertung der Besuche dieser Internetseite - Webtracker</h4>
            <p>Auf unserer Internetseite verwenden wir aktive Java-Script-Inhalte von externen Anbietern, sog. Webservices. Durch Aufruf unserer Internetseite erhalten diese externen Anbieter ggf. personenbezogene Informationen über Ihren Besuch auf unserer Internetseite. Hierbei ist ggf. eine Verarbeitung von Daten außerhalb der EU möglich. Sie können dies verhindern, indem Sie einen Java-Script-Blocker wie z.B. das Browser-Plug-In 'NoScript' installieren (www.noscript.net ) oder Java-Script in Ihrem Browser deaktivieren. Hierdurch kann es zu Funktionseinschränkungen auf Internetseiten kommen, die Sie besuchen.
            Wir verwenden folgende externe Webservices:
            </p>
            <h5>Google-Analytics</h5>
            <ul>
                <li>Auf unserer Seite verwenden wir den Webtracking-Service des Unternehmens Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland (nachfolgend: Google-Analytics). Google-Analytics nutzt im Rahmen des Webtrackings Cookies, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung unserer Website und Ihres Surfverhaltens ermöglichen (sogenanntes Tracken). Wir führen diese Analyse auf Basis des Tracking-Services von Google-Analytics durch, um unser Internetangebot ständig zu optimieren und besser verfügbar zu machen. Im Rahmen der Benutzung unserer Webseite werden dabei Daten, wie insbesondere Ihre IP-Adresse und Ihre Nutzeraktivitäten an Server des Unternehmens Google Ireland Limited übertragen und außerhalb der Europäischen Union, z.B. in den USA verarbeitet und gespeichert.
                    Die EU-Kommission hat festgestellt, dass in den USA ein angemessenes Datenschutzniveau bestehen kann, wenn sich das datenverarbeitende Unternehmen dem US-EU-Privacy-Shield Abkommen unterworfen hat und der Datenexport in die USA auf diesem Weg zulässig ausgestaltet wurde. Durch die Aktivierung der IP-Anonymisierung innerhalb des Google-Analytics Tracking-Codes dieser Internetseite, wird Ihre IP-Adresse von Google-Analytics vor der Übertragung anonymisiert. Diese Website nutzt einen Google-Analytics-Tracking-Code, der um den Operator gat._anonymizeIp(); erweitert wurde, um nur eine anonymisierte Erfassung von IP-Adressen (sog. IP-Masking) zu ermöglichen.</li>
                <li>Art. 6 Abs.1 lit.a DSGVO (Einwilligung), entweder im Rahmen der Registrierung bei Google (Eröffnung eines Google-Accounts und Akzeptanz der dort implementierten Datenschutzhinweise) oder, falls Sie sich nicht bei Google registriert haben, durch explizite Einwilligung beim Öffnen unserer Seite.</li>
                <li>In unserem Auftrag wird Google diese Informationen nutzen, um Ihren Besuch auf dieser Internetseite auszuwerten, Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber uns zu erbringen. Die im Rahmen von Google-Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten der Google Ireland Limited zusammengeführt.</li>
                <li>Google wird die für die Bereitstellung des Webtracking relevanten Daten solange speichern, wie es notwendig ist, um den gebuchten Webservice zu erfüllen. Die Datenerhebung und Speicherung erfolgt anonymisiert. Soweit doch Personenbezug bestehen sollte, werden die Daten unverzüglich gelöscht, soweit diese keinen gesetzlichen Aufbewahrungspflichten unterfallen. In jedem Fall erfolgt die Löschung nach Ablauf der Aufbewahrungspflicht.</li>
                <li>Sie können die Erfassung und Weiterleitung der personenbezogenen Daten an Google (insb. Ihrer IP-Adresse) sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie die Ausführung von Script-Code in Ihrem Browser deaktivieren, einen Script-Blocker in Ihrem Browser installieren (diesen finden Sie z.B. unter www.noscript.net oder www.ghostery.com ) oder die „Do Not Track“ Einstellung Ihres Browsers aktivieren. Sie können darüber hinaus die Erfassung der durch den Google-Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link (http://tools.google.com/dlpage/gaoptout?hl=de ) verfügbare Browser-Plug-In herunterladen und installieren. Die Sicherheits- und Datenschutzgrundsätze von Google finden Sie unter https://policies.google.com/privacy</li>
            </ul>
            <p> Die Cookie-Richtlinien der Seite haben Sie gegenwärtig <span css={{fontWeight: 600, color: orange}}>{cookieState == "true" ? "akzeptiert" : "abgelehnt"}</span></p>
            <div css={{marginLeft: "2em", display: "flex", flexDirection:"column", alignItems: "flex-start", justifyContent: "center", flexWrap: "wrap"}}>
            <label onClick={()=> accept("true")} class="container">Google Analytics aktiviert
                {cookieState == "true" ? <input type="radio"  name="radio" value checked /> : <input type="radio"  name="radio" value /> }
                <span class="checkmark"></span>
            </label>
            <label  class="container" onClick={()=> accept("false")}  >Google Analytics deaktiviert 
                {cookieState == "false" ? <input type="radio"  name="radio" value checked /> : <input type="radio"  name="radio" value /> }
                <span class="checkmark"></span>
            </label>
            </div>
        </TextBoxFull>
        <TextBoxFull>
            <h4>5. Einbindung externer Webservices und Verarbeitung von Daten außerhalb der EU</h4>
            <p>Auf unserer Internetseite verwenden wir aktive Java-Script-Inhalte von externen Anbietern, sog. Webservices. Durch Aufruf unserer Internetseite erhalten diese externen Anbieter ggf. personenbezogene Informationen über Ihren Besuch auf unserer Internetseite. Hierbei ist ggf. eine Verarbeitung von Daten außerhalb der EU möglich. Sie können dies verhindern, indem Sie einen Java-Script-Blocker wie z.B. das Browser-Plug-In 'NoScript' installieren (www.noscript.net ) oder Java-Script in Ihrem Browser deaktivieren. Hierdurch kann es zu Funktionseinschränkungen auf Internetseiten kommen, die Sie besuchen.
                Wir verwenden folgende externe Webservices:
            </p>
            <ul>
                <li><h5>Google-Apis</h5>
                <p>Auf unserer Webseite wird ein Webservice des Unternehmens Google Ireland Limited, Gordon House, Barrow Street, 4 Dublin, Irland (nachfolgend: Google-Apis) nachgeladen. Wir nutzen diese Daten, um die volle Funktionalität unserer Webseite zu gewährleisten. In diesem Zusammenhang wird Ihr Browser ggf. personenbezogene Daten an Google-Apis übermitteln. Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs.1 lit. f DSGVO. Das berechtigte Interesse besteht in einer fehlerfreien Funktion der Internetseite. Google-Apis hat sich im Rahmen des EU-US-Privacy-Shield-Abkommens selbst zertifiziert (vgl. https://www.privacyshield.gov/list ). Die Löschung der Daten erfolgt, sobald der Zweck ihrer Erhebung erfüllt wurde. Weitere Informationen zum Handling der übertragenen Daten finden Sie in der Datenschutzerklärung von Google-Apis: https://policies.google.com/privacy . Sie können die Erfassung sowie die Verarbeitung Ihrer Daten durch Google-Apis verhindern, indem Sie die Ausführung von Script-Code in Ihrem Browser deaktivieren oder einen Script-Blocker in Ihrem Browser installieren (diesen finden Sie z.B. unter www.noscript.net oder www.ghostery.com ).</p>
                </li>
                <li><h5>gstatic</h5>
                <p>Auf unserer Webseite wird ein Webservice des Unternehmens Google Ireland Limited, Gordon House, Barrow Street, 4 Dublin, Irland (nachfolgend: gstatic) nachgeladen. Wir nutzen diese Daten, um die volle Funktionalität unserer Webseite zu gewährleisten. In diesem Zusammenhang wird Ihr Browser ggf. personenbezogene Daten an gstatic übermitteln. Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs.1 lit. f DSGVO. Das berechtigte Interesse besteht in einer fehlerfreien Funktion der Internetseite. gstatic hat sich im Rahmen des EU-US-Privacy-Shield-Abkommens selbst zertifiziert (vgl. https://www.privacyshield.gov/list ). Die Löschung der Daten erfolgt, sobald der Zweck ihrer Erhebung erfüllt wurde. Weitere Informationen zum Handling der übertragenen Daten finden Sie in der Datenschutzerklärung von gstatic: https://policies.google.com/privacy . Sie können die Erfassung sowie die Verarbeitung Ihrer Daten durch gstatic verhindern, indem Sie die Ausführung von Script-Code in Ihrem Browser deaktivieren oder einen Script-Blocker in Ihrem Browser installieren (diesen finden Sie z.B. unter www.noscript.net oder www.ghostery.com ).</p>
                </li>
              
            </ul>

        </TextBoxFull>
        <TextBoxFull>
            <h4>6. Unterrichtung über die Nutzung von Cookies</h4>
            <ul>
                <li>Auf verschiedenen Seiten verwenden wir Cookies, um die Nutzung bestimmter Funktionen unserer Website zu ermöglichen. Bei den sog. 'Cookies' handelt es sich um kleine Textdateien, die Ihr Browser auf Ihrem Rechner ablegen kann. Diese Textdateien enthalten eine charakteristische Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim erneuten Aufrufen unserer Website ermöglichen. Der Vorgang des Ablegens einer Cookie-Datei wird auch 'ein Cookie setzen' genannt.</li>
                <li>Art. 6 Abs.1 lit. f DSGVO (berechtigtes Interesse). Unser berechtigtes Interesse besteht in der Erhaltung der vollen Funktionsfähigkeit unserer Internetseite, die Steigerung der Bedienbarkeit sowie die Ermöglichung einer individuelleren Kundenansprache. Eine Identifizierung einzelner Seitenbesucher ist uns mit Hilfe der Cookie-Technologie nur möglich, wenn der Seitenbesucher uns zuvor auf Basis einer gesonderten Einwilligung entsprechende personenbezogene Daten überlassen hat.</li>
                <li>Die Cookies werden von unserer Website gesetzt, um die volle Funktionsfähigkeit unserer Internetseite zu erhalten und die Bedienbarkeit zu verbessern. Zudem ermöglicht uns die Cookie-Technologie, einzelne Besucher durch Pseudonyme, z.B. eine individuelle, beliebige ID, wiederzuerkennen, so dass es uns möglich ist, individuellere Leistungen anzubieten.</li>
                <li>Die Speicherung unserer Cookies erfolgt bis zur Löschung in Ihrem Browser oder, wenn es sich um einen Session-Cookie handelt, bis die Session abgelaufen ist.</li>
                <li>Sie können Ihren Browser selbst nach Ihren Wünschen so einstellen, dass das Setzen von Cookies generell verhindert wird. Sie können dann von Fall zu Fall über die Annahme von Cookies entscheiden oder die Annahme von Cookies grundsätzlich akzeptieren. Cookies können zu unterschiedlichen Zwecken eingesetzt werden, z.B. um zu erkennen, dass Ihr PC schon einmal eine Verbindung zu unserem Webangebot hatte (dauerhafte Cookies) oder um zuletzt angesehene Angebote zu speichern (Sitzungs-Cookies). Wir setzen Cookies ein, um Ihnen einen gesteigerten Benutzerkomfort zu bieten. Um unsere Komfortfunktionen zu nutzen, empfehlen wir Ihnen, die Annahme von Cookies für unser Webangebot zu erlauben. Die Widerspruchs- und Beseitigungsmöglichkeiten richten sich im Übrigen nach den nachfolgend in dieser Datenschutzerklärung geschilderten generellen Regelungen zum datenschutzrechtlichen Widerspruchsrecht und Löschungsanspruch.</li>
            </ul>
        </TextBoxFull>
        <TextBoxFull>
            <h4>7. Datensicherheit und Datenschutz, Kommunikation per E-Mail</h4>
            <p>
            Ihre personenbezogenen Daten werden durch technische und organisatorische Maßnahmen so bei der Erhebung, Speicherung und Verarbeitung geschützt, dass sie für Dritte nicht zugänglich sind. Bei einer unverschlüsselten Kommunikation per E-Mail kann die vollständige Datensicherheit auf dem Übertragungsweg zu unseren IT-Systemen von uns nicht gewährleistet werden, sodass wir bei Informationen mit hohem Geheimhaltungsbedürfnis eine verschlüsselte Kommunikation oder den Postweg empfehlen.
            </p>
        </TextBoxFull>
        <TextBoxFull>
            <h4>8. Automatische E-Mail Archivierung</h4>
            <ul>
                <li>Wir weisen Sie ausdrücklich darauf hin, dass unser Mailsystem über ein automatisiertes Archivierungsverfahren verfügt. Alle ein- und ausgehenden E-Mails werden hierdurch revisionssicher digital archiviert.</li>
                <li>Art. 6 Abs.1 lit.f DSGVO (berechtigtes Interesse). Unser berechtigtes Interesse besteht in der Einhaltung steuerrechtlicher und handelsrechtlicher Vorgaben (z.B. §§ 146, 147 AO).</li>
                <li>Der Zweck der Archivierung besteht in der Einhaltung steuerrechtlicher und handelsrechtlicher Vorgaben (z.B. §§ 146, 147 AO).</li>
                <li>Die Speicherung unserer Mailkommunikation erfolgt bis zum Ablauf steuerrechtlicher und handelsrechtlicher Aufbewahrungspflichten. Die Aufbewahrungsdauer kann bis zu 10 Jahren betragen.</li>
                <li>Zudem weisen wir darauf hin, dass wir ausschließlich Bewerbungsunterlagen in PDF-Dateiform beachten. Gezippte (WinZip, WinRAR, 7Zip, etc.) Dateien werden von unseren Sicherheitssystemen ausgefiltert und nicht zugestellt. Bewerbungen in Word-Dateiformat und andere Dateiformate beachten wir nicht und löschen sie ungelesen. Bitte beachten Sie, dass per E-Mail unverschlüsselt übermittelte Bewerbungsunterlagen ggf. von Dritten geöffnet werden können, bevor sie in unseren IT-Systemen ankommen. Wir gehen davon aus, dass wir unverschlüsselte Bewerbungsmails ebenfalls unverschlüsselt beantworten dürfen. Sollten Sie dies nicht wünschen, so geben Sie uns bitte einen Hinweis in Ihrer Bewerbungsmail.</li>
            </ul>
        </TextBoxFull>
        <TextBoxFull>
            <h4>9. Widerruf von Einwilligungen - Datenauskünfte und Änderungswünsche – Löschung & Sperrung von Daten</h4>
            <p>Sie haben den Anspruch, dass wir Ihnen die Ihre Person betreffenden Daten, die Sie uns übermittelt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zur Verfügung stellen. Sie können von uns zudem verlangen, dass wir diese Daten einem Dritten, auf Ihre erste Weisung hin unverzüglich übermitteln, sofern die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gem. Art. 6 Abs. 1 lit. b DSGVO beruht und die Verarbeitung durch uns im Rahmen einer automatisierten Datenverarbeitung erfolgt.<br />
            Bei der Ausübung dieses Rechts der Datenübertragbarkeit haben Sie ferner das Recht, zu erwirken, dass die Sie betreffenden personenbezogenen Daten direkt einem anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist. Freiheiten und Rechte anderer Personen dürfen hierdurch nicht beeinträchtigt werden.<br/>
            Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener Daten, die für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.</p>
            
        </TextBoxFull>
        <TextBoxFull>
            <h4>11. Beschwerderecht bei der Aufsichtsbehörde gem. Art. 77 Abs. 1 DSGVO</h4>
            <p>Sofern Sie den Verdacht haben, dass auf unserer Seite Ihre Daten rechtswidrig verarbeitet werden, können Sie selbstverständlich jederzeit eine gerichtliche Klärung der Problematik herbeiführen. Unabhängig davon steht Ihnen die Möglichkeit zur Verfügung, sich an eine Aufsichtsbehörde zu wenden. Das Beschwerderecht steht Ihnen in dem EU-Mitgliedstaat Ihres Aufenthaltsortes, Ihres Arbeitsplatzes und/oder des Orts des vermeintlichen Verstoßes zu, d.h. Sie können die Aufsichtsbehörde, an die Sie sich wenden, an den oben genannten Orten wählen. Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet Sie dann über den Stand und die Ergebnisse Ihrer Eingabe, einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs nach Art. 78 DSGVO.</p>
        </TextBoxFull>
        
    </FlexContainer>


    </Wrapper>

)}

export default Datenschutz;